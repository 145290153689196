import React, { useState } from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout";
import Home from "./Components/Home";
import About from "./Components/About";
import Gallery from "./Components/Gallery";
import { AppContext } from "./Utils/contexts";
import { QueryParamProvider } from "use-query-params";
import baseMuiTheme from "./Assets/styles/theme";
import Contact from "./Components/Contact";
import Stepsheets from "./Components/Stepsheets";

export default function App() {
  const [showSwingingDoors, setShowSwingingDoors] = useState(false);
  return (
    <AppContext.Provider value={{ showSwingingDoors, setShowSwingingDoors }}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={baseMuiTheme}>
            <Layout>
              <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/Home" exact element={<Home />} />
                <Route path="/About" exact element={<About />} />
                <Route path="/Gallery/:Section" element={<Gallery />} />
                <Route path="/Gallery" element={<Gallery />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/Stepsheets" element={<Stepsheets />} />
              </Routes>
            </Layout>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryParamProvider>
    </AppContext.Provider>
  );
}
