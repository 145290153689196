import React from "react";
//import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export default function MobileHome(props) {
  return (
    <Stack spacing={2}>
      <img
        src="https://i.pinimg.com/originals/86/25/21/862521b0e0dfc06f81378b6a28105d8d.jpg"
        alt="banner"
      />
      <Typography variant="h4" align="center">
        Welcome To The Stompin' Ground!
      </Typography>
      <Typography variant="body1" align="center">
        Please excuse the dust while we clean off our boots and get this site up
        and running!
      </Typography>
      <img
        src="https://i.pinimg.com/originals/44/54/a0/4454a040fed4579bce058c5f893c62a2.jpg"
        alt="hw1"
      />
      <Typography align="center">
        In the meantime, visit Dancin' Cat on Facebook here:{" "}
        <a
          href="https://www.facebook.com/DancinDJCat"
          style={{ color: "#FF1616" }}
          target="_blank"
          rel="noreferrer"
        >
          Dancin' Cat FB
        </a>
      </Typography>
      <Typography align="center">- OR -</Typography>
      <Typography variant="body1" align="center">
        Send us an email at DancingDjCat@gmail.com !
      </Typography>
      {/* <Link to={{ pathname: "/Gallery/BG2022" }}> */}
      <img
        src="https://i.pinimg.com/originals/77/a2/99/77a2990e8995e9c0a0c1074f817e00dc.jpg"
        alt="Brantley Gilbert Dance Crew Summer 2022"
      />
      {/* </Link> */}
    </Stack>
  );
}
