import React from "react";
import DesktopLogo from "../../Assets/images/DesktopLogo.png";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

export default function DesktopHeader(props) {
  return (
    <Grid container alignItems="center" sx={{ marginTop: "1rem" }}>
      <Grid item xs={2}>
        <Link to={{ pathname: "/" }}>
          <img
            src={DesktopLogo}
            width="300px"
            className="m-2"
            alt="site icon"
          />
        </Link>
      </Grid>
      <Grid item xs={10}>
        <Stack direction="row" spacing={2} sx={{ float: "right" }}>
          <Link to={{ pathname: "/" }} style={{ textDecoration: "none" }}>
            <Button>Home</Button>
          </Link>
          <Divider orientation="vertical" flexItem />
          <Link to={{ pathname: "/About" }} style={{ textDecoration: "none" }}>
            <Button>About</Button>
          </Link>
          <Divider orientation="vertical" flexItem />
          {/* <Link
            to={{ pathname: "/Gallery" }}
            style={{ textDecoration: "none" }}
          >
            <Button>Gallery</Button>
          </Link> */}
          {/* <Divider orientation="vertical" flexItem /> */}
          <Link
            to={{ pathname: "/Stepsheets" }}
            style={{ textDecoration: "none" }}
          >
            <Button>Stepsheets</Button>
          </Link>
          {/* <Divider orientation="vertical" flexItem />
          <Link
            to={{ pathname: "/Contact" }}
            style={{ textDecoration: "none" }}
          >
            <Button>Contact</Button>
          </Link> */}
        </Stack>
      </Grid>
    </Grid>
  );
}
