import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import QRCode from "../../Assets/images/sg_stepsheetslist_qrcode.png";

export default function MobileStepsheets(props) {
  return (
    <Stack spacing={2} alignItems="center" justifyContent="center">
      <Typography variant="h4" align="center">
        Scan the QR code below or click the link to go to our public stepsheet
        list on Copperknob!
      </Typography>
      <Typography align="center">
        Click here:{" "}
        <a
          href="https://www.copperknob.co.uk/lists/23077/stompin-ground-stepsheets"
          style={{ color: "#FF1616" }}
          target="_blank"
          rel="noreferrer"
        >
          Stompin' Ground Stepsheets List - Copperknob
        </a>
      </Typography>
      <img src={QRCode} alt="QR Code for Copperknob" width="100%" />
      <Divider flexItem />
      <Typography variant="h4" align="center">
        Follow our FREE public Spotify playlist!
      </Typography>
      <iframe
        style={{ borderRadius: "12px" }}
        title="Spotify Playlist"
        src="https://open.spotify.com/embed/playlist/0ppKEXuCAVYDrxwgtbTVX0?utm_source=generator&theme=0"
        width="100%"
        height="352"
        frameBorder="0"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </Stack>
  );
}
