import React from "react";
import { Mobile, Tablet, Desktop } from "../Utils/deviceHelpers";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import WordsImage from "../Assets/images/SG Banner Info.png";
import MobileHeader from "./Mobile/MobileHeader";
import DesktopHeader from "./Desktop/DesktopHeader";
import DesktopLogo from "../Assets/images/DesktopLogo.png";

export default function Layout(props) {
  return (
    <Container>
      <header>
        <Mobile>
          <Link to={{ pathname: "/" }}>
            <img
              src={DesktopLogo}
              width="100%"
              className="m-2"
              alt="site icon"
            />
          </Link>
          <MobileHeader />
        </Mobile>
        <Tablet>
          <Link to={{ pathname: "/" }}>
            <img
              src={DesktopLogo}
              width="100%"
              className="m-2"
              alt="site icon"
            />
          </Link>
          <MobileHeader />
        </Tablet>
        <Desktop>
          <DesktopHeader />
        </Desktop>
      </header>
      <Paper elevation={1} sx={{ margin: "2rem", padding: "2rem" }}>
        {props.children}
      </Paper>
      <footer>
        <Stack justifyContent="center" sx={{ marginBottom: "1rem" }}>
          <img src={WordsImage} alt="words" />
          <Typography variant="body1" align="center">
            Copyright 2023 | Ashley Wilson | @the_tech_witch{" "}
          </Typography>
        </Stack>
      </footer>
    </Container>
  );
}
