import { createTheme } from "@mui/material/styles";

const bodyFont = "Raleway";
const headerFont = "Special Elite";
const subHeaderFont = "Sancreek";

const white = "#ffffff";
//const black = "#000000";
const red = "#FF1616";

const baseMuiTheme = createTheme({
  palette: {
    type: "dark",
    mode: "dark",
    primary: {
      //light: lightBlue,
      main: red,
      dark: red,
      contrastText: white,
    },
    // secondary: {
    //   light: lightGray,
    //   main: sortofLightGray,
    //   dark: gray,
    // },
    // error: {
    //   light: lightRed,
    //   main: red,
    //   dark: mediumRed,
    //   contrastText: white,
    // },
    // warning: {
    //   light: lightYellow,
    //   main: yellow,
    //   dark: darkGold,
    // },
    // info: {
    //   light: infoLightBlue,
    //   main: babyBlue,
    //   dark: infoDarkBlue,
    //   contrastText: white,
    // },
    // success: {
    //   light: lightGreen,
    //   main: green,
    //   dark: darkGreen,
    //   contrastText: white,
    // },
    // dark: {
    //   main: black,
    //   contrastText: white,
    // },
  },
  typography: {
    fontFamily: bodyFont,
    h1: {
      fontFamily: headerFont,
      color: white,
      fontSize: "4rem",
    },
    h2: {
      fontFamily: subHeaderFont,
      color: white,
    },
    h3: {
      fontFamily: subHeaderFont,
      letterSpacing: "0.2rem",
    },
    body1: {
      color: white,
    },
    subtitle1: {
      fontFamily: headerFont,
      fontSize: "2rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          justifySelf: "center",
          cursor: "pointer",
          fontSize: "1.5rem",
          height: "3rem",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: white,
        },
      },
    },
  },
});

export default baseMuiTheme;
