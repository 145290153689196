import React from "react";
import { Desktop, Mobile, Tablet } from "../Utils/deviceHelpers";
import MobileAbout from "./Mobile/MobileAbout";
import DesktopAbout from "./Desktop/DesktopAbout";

export default function About(props) {
  return (
    <>
      <Mobile>
        <MobileAbout />
      </Mobile>
      <Tablet>
        <MobileAbout />
      </Tablet>
      <Desktop>
        <DesktopAbout />
      </Desktop>
    </>
  );
}
