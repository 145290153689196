import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { FaFacebook } from "react-icons/fa";

export default function MobileAbout(props) {
  return (
    <Stack spacing={2}>
      <Typography variant="h1">Meet Dancin' Cat</Typography>
      <img
        src="https://i.pinimg.com/originals/8b/cd/bd/8bcdbd841ccc8cef5d8f2c37b2ba6038.jpg"
        alt="Cat on Stage Hardywood February 4, 2023"
      />
      <Typography variant="subtitle1">
        ...the face and founder of Stompin' Ground...
      </Typography>
      <Typography>
        Dancin' Cat has enjoyed the privilege of working tandem with so many
        Live Music Bands, making new friends, and building bonds here in
        Richmond, VA since 2016.
      </Typography>
      <Typography>
        <FaFacebook /> Find her on{" "}
        <a
          href="https://www.facebook.com/DancinDJCat"
          style={{ color: "#FF1616" }}
          target="_blank"
          rel="noreferrer"
        >
          Facebook
        </a>
        !
      </Typography>
      <Divider flexItem />
      <Typography variant="subtitle1">Opened Shows For:</Typography>
      <List>
        <ListItem disablePadding>
          <ListItemText primary="Toby Keith" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Chase Rice" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Brantley Gilbert" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Kane Brown" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Little Big Town" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Dan & Shae" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Billy Currington" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Lee Brice" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Travis Tritt" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Brothers Osbourne" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Old Dominion" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Lynyrd Skynyrd" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Big Daddy Yankee" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Montel Jordan" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Biz Markee" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Rob Base" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Granger Smith" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Dustin Lynch" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Brett Eldredge" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Gary Allen" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Brett Young" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Chris Young" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Jake Owen" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Alabama" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Justin Moore" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Chris Lane" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Temptations" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="K95 Country Fest" />
        </ListItem>
      </List>
      <Divider flexItem />
      <Typography variant="subtitle1">Tandem Bands:</Typography>
      <List>
        <ListItem disablePadding>
          <ListItemText primary="Tony Jackson" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Jesse Keith Whitley" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="BuckShot" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Chase Payne" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="The Mullins Sisters" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="East of Blueridge" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Josh Duncan Band" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Shooters Band" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Cobalt Whiskey" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Kenzie Kincaid Band" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Main Street Station" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Anderson Highway" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="LoCountry" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="220 South" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Desert Wind" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Honky Tonk Heros" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Dukes of Haggard" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Rock Bottom Bridge" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Saddled Jack" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="The Ryan Greer Band" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Jay Turner & the Swinging Doors" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Troy Breslow & the Compnay Band" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Steel Wheels Band RVA" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Pat Russell Band" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Tailgate Down" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="All My Rowdy Friends" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Cedar Creek" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Runnin' Shine" />
        </ListItem>
      </List>
    </Stack>
  );
}
