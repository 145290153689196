import React from "react";
import { Desktop, Mobile, Tablet } from "../Utils/deviceHelpers";
import MobileStepsheets from "./Mobile/MobileStepsheets";
import DesktopStepsheets from "./Desktop/DesktopStepsheets";

export default function Stepsheets(props) {
  return (
    <>
      <Mobile>
        <MobileStepsheets />
      </Mobile>
      <Tablet>
        <MobileStepsheets />
      </Tablet>
      <Desktop>
        <DesktopStepsheets />
      </Desktop>
    </>
  );
}
