import React from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

export default function Gallery(props) {
  const gallerySection = useParams();
  console.log(gallerySection.Section);

  return (
    <Stack justifyContent="center" alignItem="center" spacing={2}>
      <Typography variant="h1" align="center">
        Country Dance "Cowboy Hat" Night | Hardywood West Creek | February 4,
        2023
      </Typography>
      <img
        src="https://i.pinimg.com/originals/af/47/2b/af472b85cd083f79660a2dc65ca3dc00.jpg"
        alt="HW Feb 4 2023"
      />
      <Divider flexItem />
      <Typography variant="h1" align="center">
        Brantley Gilbert Concert | Summer 2022
      </Typography>
      <img
        src="https://i.pinimg.com/originals/77/a2/99/77a2990e8995e9c0a0c1074f817e00dc.jpg"
        alt="BG Crew Summer 2022"
      />
    </Stack>
  );
}
