import React, { useState } from "react";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { GiCowboyBoot } from "react-icons/gi";
import { FaHatCowboy } from "react-icons/fa";
import { IoFootsteps } from "react-icons/io5";
//import { AiOutlineForm } from "react-icons/ai";

export default function MobileHeader(props) {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpenMenu(true)}
        sx={{ width: "100%", textTransform: "none" }}
        variant="outlined"
      >
        Menu
      </Button>
      <Drawer
        anchor="bottom"
        open={openMenu}
        onClose={() => setOpenMenu(false)}
      >
        <MenuList>
          <Link
            to={{
              pathname: "/",
            }}
            style={{ textDecoration: "none" }}
            onClick={() => setOpenMenu(false)}
          >
            <MenuItem>
              <ListItemIcon>
                <GiCowboyBoot />
              </ListItemIcon>
              <ListItemText>Home</ListItemText>
            </MenuItem>
          </Link>
          <Link
            to={{
              pathname: "/About",
            }}
            style={{ textDecoration: "none" }}
            onClick={() => setOpenMenu(false)}
          >
            <MenuItem>
              <ListItemIcon>
                <FaHatCowboy />
              </ListItemIcon>
              <ListItemText>About</ListItemText>
            </MenuItem>
          </Link>
          {/* <Link
            to={{
              pathname: "/Gallery",
            }}
            style={{ textDecoration: "none" }}
            onClick={() => setOpenMenu(false)}
          >
            <MenuItem>
              <ListItemIcon>
                <IoImagesOutline />
              </ListItemIcon>
              <ListItemText>Gallery</ListItemText>
            </MenuItem>
          </Link> */}
          <Link
            to={{
              pathname: "/Stepsheets",
            }}
            style={{ textDecoration: "none" }}
            onClick={() => setOpenMenu(false)}
          >
            <MenuItem>
              <ListItemIcon>
                <IoFootsteps />
              </ListItemIcon>
              <ListItemText>Stepsheets</ListItemText>
            </MenuItem>
          </Link>
          {/* <Link
            to={{
              pathname: "/Contact",
            }}
            style={{ textDecoration: "none" }}
            onClick={() => setOpenMenu(false)}
          >
            <MenuItem>
              <ListItemIcon>
                <AiOutlineForm />
              </ListItemIcon>
              <ListItemText>Contact</ListItemText>
            </MenuItem>
          </Link> */}
        </MenuList>
      </Drawer>
    </>
  );
}
