import React from "react";
import { Desktop, Mobile, Tablet } from "../Utils/deviceHelpers";
import MobileHome from "./Mobile/MobileHome";
import DesktopHome from "./Desktop/DesktopHome";

export default function Home(props) {
  return (
    <>
      <Mobile>
        <MobileHome />
      </Mobile>
      <Tablet>
        <MobileHome />
      </Tablet>
      <Desktop>
        <DesktopHome />
      </Desktop>
    </>
  );
}
